import { defineStore } from "pinia";

export const useNotificationStore = defineStore("notificationStore", {
	state: () => ({
		unreadCount: 0, 
	}),
	actions: {
		async fetchUnreadCount() {
			try {
                const count = await $fetch("/api/notification/count");
				this.unreadCount = count;
			} catch (error) {
				console.error("Erro ao buscar notificações não lidas:", error);
			}
		},
		setUnreadCount(count: number) {
			this.unreadCount = count;
		},
		decrementUnreadCount() {
			if (this.unreadCount > 0) this.unreadCount--;
		},
		incrementUnreadCount() {
			this.unreadCount++;
		},
	},
});
